vue
<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <p class="title-bold">Neuromoduladores para la hiperhidrosis</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <img class="img-fluid" alt="rinomodelación" src="../../assets/hiperhidrosis.svg" />
            </div>

            <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
                <Card styleClass="p-card-shadow">
                    <template #content>
                        <p>Los <span class="p-text-bold">neuromoduladores</span>, ofrecen un alivio efectivo para la <span
                                class="p-text-bold">hiperhidrosis</span>, reduciendo la producción de sudor de forma segura
                            y controlada. Este tratamiento minimiza la sudoración excesiva y mejora la calidad de vida de
                            quienes padecen este trastorno. Los efectos duran meses, proporcionando una solución temporal.
                            Los neuromoduladores para tratar la hiperhidrosis son una opción eficaz y de bajo riesgo. Los
                            resultados pueden variar según el paciente, pero generalmente se experimenta una disminución
                            significativa en la sudoración en las áreas tratadas. Este enfoque no invasivo ofrece comodidad
                            y mejora la calidad de vida.</p>

                        <span class="p-text-bold">1.¿Cuánto tiempo lleva ver una reducción significativa en la
                            sudoración después del tratamiento de hiperhidrosis con neuromoduladores?</span>
                        <p>Por lo general, se pueden notar mejoras en la
                            sudoración en unos pocos días, con resultados óptimos alcanzados en aproximadamente una semana.
                        </p>

                        <span class="p-text-bold">2.¿Existen efectos secundarios comunes después del tratamiento?</span> 
                        <p>Los efectos secundarios suelen ser leves y
                            temporales, como rojecimiento o inflamación en el sitio de la inyección, que desaparecen en
                            pocos días.
                        </p>

                       <span class="p-text-bold">3.¿Cuánto tiempo dura el alivio de la hiperhidrosis con
                            neuromoduladores y con qué frecuencia debo repetir el tratamiento?</span> <p>Los efectos del tratamiento suelen durar de 4 a 12
                            meses. La frecuencia de las inyecciones posteriores varía según la respuesta individual, pero
                            generalmente se requieren tratamientos de seguimiento una o dos veces al año.</p>
                    </template>

                    <template #footer>
                        <span>Para más información no dude en ponerse en contacto con nuestro
                            Centro médico al 637 857 384.</span>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import Card from 'primevue/card';
export default {
    name: 'RinomodelacionVue',
    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    components: {
        Card
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>
.title-bold {
    font-size: 30px;
    font-weight: bold
}

.img-fluid {
    width: 100%;
    height: auto;
}

@media (min-width: 576px) {
    .img-fluid {
        max-width: 50%;
        height: auto;
    }
}
</style>